<template>
	<div>
		<v-navigation-drawer v-model="sidebar" light floating clipped app width="350" height="100%" color="rgba(255,255,255,0.96)" class="mt-1 mr-1 pa-10 rounded-lg rounded-l-0">
			<v-list flat light>
				<v-list-item-group v-model="tab" light>
					<v-list-item color="themepurple" v-for="(item, i) in menuitems" :key="i" :disabled="i == tab">
						<v-list-item-title :key="i"> {{ item.step }} {{ item.title }} </v-list-item-title>
					</v-list-item>
				</v-list-item-group>
				<v-spacer></v-spacer>
			</v-list>

			<p class="bottom-text">Bevindingenformulier (Programma)</p>
		</v-navigation-drawer>
		<v-app-bar-nav-icon class="left-top-absolute ma-4" @click.stop="sidebar = !sidebar"></v-app-bar-nav-icon>
		<v-main class="pa-0 ml-1 pt-0 pa-15">
			<div v-if="tab === 0">
				<h1 class="mb-5">Bevindingenformulier <small>Programma</small></h1>
				<v-row>
					<v-col cols="12" md="6">
						<p>De transformatieagenda werkt volgens de leidende principes die in de transformatiemeetlat staan. Dit zijn tevens de effecten die we willen bereiken. Door gebruik te maken van de meetlat in de monitoringstoolkit, als gespreks- en kwaliteitsmodel gaan we meer één taal spreken, is er een eenduidige werkwijze en wordt er op structurele basis kennis gedeeld. Hierdoor begrijpen partners elkaar beter en komt men gezamenlijk tot duurzame verbeterideeën en bevorderen we met elkaar de kwaliteit van de jeugdhulp in de regio Groningen. We zetten in op netwerken en leren van elkaar, vanuit de uitgangspunten: verminderen, voorkomen, versterken en leren. In dit formulier brengen we de informatie vanuit de dialoogformulieren van de afgelopen periode samen tot tussentijdse resultaten en bevindingen van het programma.</p>
					</v-col>
				</v-row>

				<v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined @click="submit('concept')" v-if="edit" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 1">
				<v-row>
					<v-col cols="12" md="6">
						<h1 class="mb-5">Het programma</h1>

						<label for="">
							<p>Titel</p>
						</label>
						<v-text-field v-model="data.title" :disabled="edit" class="theme-input" solo background-color="#F6E3E7" title="Titel"></v-text-field>
						<label for="">
							<p>Periode</p>
						</label>
						<v-text-field v-model="data.period" :disabled="edit" class="theme-input" solo background-color="#F6E3E7" title="Periode"></v-text-field>
						<v-expansion-panels accordion flat>
							<accordion title=" <p>Welke investering hebben we de afgelopen periode gedaan als het gaat om <b>Verminderen, voorkomen, versterken en leren?</b></p>" :style="`border-color: #EDC7CF!important;`">
								<v-textarea :disabled="edit" v-model="data.investment" background-color="#F6E3E7" class="theme-input" solo></v-textarea>
							</accordion>

							<accordion title="<p>Wat zijn successen en zorgen?</p>" :style="`border-color: #EDC7CF!important;`">
								<v-textarea :disabled="edit" v-model="data.successes_and_worries" background-color="#F6E3E7" class="theme-input" solo></v-textarea>
							</accordion>

							<accordion title=" <p>Doen we de goeie dingen?</p>" :style="`border-color: #EDC7CF!important;`">
								<v-textarea :disabled="edit" v-model="data.are_we_doing_good_work" background-color="#F6E3E7" class="theme-input" solo></v-textarea>
							</accordion>

							<accordion title="  <p>Ontbreken er zaken?</p>" :style="`border-color: #EDC7CF!important;`">
								<v-textarea :disabled="edit" v-model="data.are_we_missing_something" background-color="#F6E3E7" class="theme-input" solo></v-textarea>
							</accordion>

							<accordion title=" <p>Welk effect heeft dat op ambities? (Gebruik hier voorbeelden en geef reeds behaald en/of verwacht effect aan)</p>" :style="`border-color: #EDC7CF!important;`">
								<v-textarea :disabled="edit" v-model="data.ambition_effect" background-color="#F6E3E7" class="theme-input" solo></v-textarea>
							</accordion>

							<accordion title="  <p>Waar vragen we nu aandacht voor?</p>" :style="`border-color: #EDC7CF!important;`">
								<v-text-field :disabled="edit" v-model="data.attention_subject[0]" background-color="#F6E3E7" class="theme-input" solo></v-text-field>
								<v-text-field :disabled="edit" v-model="data.attention_subject[1]" background-color="#F6E3E7" class="theme-input" solo></v-text-field>
								<v-text-field :disabled="edit" v-model="data.attention_subject[2]" background-color="#F6E3E7" class="theme-input" solo></v-text-field>
								<v-text-field :disabled="edit" v-model="data.attention_subject[3]" background-color="#F6E3E7" class="theme-input" solo></v-text-field>
								<v-text-field :disabled="edit" v-model="data.attention_subject[4]" background-color="#F6E3E7" class="theme-input" solo></v-text-field>
							</accordion>
						</v-expansion-panels>
					</v-col>
					<v-col cols="12" md="6"> </v-col>
				</v-row>

				<v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined @click="submit('concept')" v-if="edit" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="next()" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<div v-if="tab === 2">
				<v-row>
					<v-col cols="12" md="6">
						<h1 class="mb-5">De ontwikkellijnen</h1>

						<h4 class="mb-2">Ambities</h4>
						<p class="mb-0">
							<b>1. Alle jeugdigen krijgen de kans zich positief te ontwikkelen</b>
						</p>
						<p>• Eind 2021 zitten jeugdigen niet langer dan 3 maanden zonder passend onderwijs aanbod thuis</p>

						<br />
						<p class="mb-0">
							<b>2. Jeugdigen groeien zo thuis en veilig mogelijk op</b>
						</p>
						<p class="mb-0">• Er zijn eind 2021 minder uithuisplaatsingen dan in 2018</p>
						<p>• Er zijn eind 2021 0 dak- en thuisloze jongeren</p>
						<br />

						<p class="mb-0">
							<b>3. Jeugdigen in Groningen worden beter ondersteund</b>
						</p>
						<p>• We realiseren een lerende omgeving met gemeenten zorgaanbieders, cliënten en ervaringsdeskundigen in Groningen vanuit het uitgangspunt “samen doen wat werkt”</p>
					</v-col>
					<v-col cols="12" md="9">
						<h4 class="mb-3">In welke mate dragen de ontwikkellijnen bij aan de meetpunten op de meetlat?</h4>
						<optiontable title="Perspectief van ouders en jeugdige versterken/verbeteren" theme="#EDC7CF">
							<optiontableitem theme="#EDC7CF" title="Het belang van de jeugdige staat voorop" @update="data.perspective_parents.intrest_paramount = $event" :val="data.perspective_parents.intrest_paramount"></optiontableitem>
							<optiontableitem theme="#EDC7CF" title="Jeugdigen en/of ouders worden actief betrokken" @update="data.perspective_parents.actively_involved = $event" :val="data.perspective_parents.actively_involved"></optiontableitem>
							<optiontableitem theme="#EDC7CF" title="Versterking van eigen kracht en verantwoordelijkheid van jeugdige en/of ouders" @update="data.perspective_parents.empowerment_power_responsibility = $event" :val="data.perspective_parents.empowerment_power_responsibility"></optiontableitem>
						</optiontable>
						<br />

						<optiontable title="Perspectief van de professionals" theme="#EDC7CF">
							<optiontableitem theme="#EDC7CF" title="Betere samenwerking: 1 gezin, 1 plan, 1 regisseur" @update="data.perspective_professional.collaboration_improvement = $event" :val="data.perspective_professional.collaboration_improvement"></optiontableitem>
							<optiontableitem theme="#EDC7CF" title="Minder regeldruk voor professionals" @update="data.perspective_professional.regulatory_burden = $event" :val="data.perspective_professional.regulatory_burden"></optiontableitem>
						</optiontable>

						<br />

						<optiontable title="Kostenbeheersing" theme="#EDC7CF">
							<optiontableitem theme="#EDC7CF" title="Meer doelmatige hulp (efficiënt/effectief)" @update="data.cost_regulation.effective_aid = $event" :val="data.cost_regulation.effective_aid"> </optiontableitem>
							<optiontableitem theme="#EDC7CF" title="Geen onnodige jeugdhulp als ook het netwerk iets kan betekenen (normaliseren en ontzorgen)" @update="data.cost_regulation.unnecessary_aid = $event" :val="data.cost_regulation.unnecessary_aid"> </optiontableitem>
						</optiontable>

						<br />

						<optiontable title="Verbeteren van kwaliteit van de jeugdhulp" theme="#EDC7CF">
							<optiontableitem theme="#EDC7CF" title="Jeugdhulp dichterbij (t)huis" @update="data.quality_improvement.closer_to_home = $event" :val="data.quality_improvement.closer_to_home"> </optiontableitem>
							<optiontableitem theme="#EDC7CF" title="Juiste zorg op het juiste moment (ook voor de complexe doelgroep)" @update="data.quality_improvement.right_moment = $event" :val="data.quality_improvement.right_moment"> </optiontableitem>
						</optiontable>
					</v-col>

					<v-col cols="12" md="6">
						<label>Zijn we met de goede dingen bezig?</label>
						<v-textarea :disabled="edit" background-color="#F6E3E7" class="theme-input" solo v-model="data.are_we_doing_good_things" title="Zijn we met de goede dingen bezig?"></v-textarea>

						<label for="">Ontbreken er zaken?</label>
						<v-textarea :disabled="edit" background-color="#F6E3E7" class="theme-input" solo v-model="data.are_we_missing_things" title="Ontbreken er zaken?"></v-textarea>

						<label for="">Hoe dragen de inspanningen bij aan de ambities?</label>
						<v-textarea :disabled="edit" background-color="#F6E3E7" class="theme-input" solo v-model="data.effort_ambition_contribution" title="Hoe dragen de inspanningen bij aan de ambities?"></v-textarea>
					</v-col>
				</v-row>

				<v-footer class="ml-2 pa-10 footer-buttons" color="transparent" fixed>
					<v-row>
						<v-col cols="12" class="text-right">
							<v-btn class="mr-3 rounded-circle" height="55px" width="55px" large icon outlined @click="submit('concept')" v-if="edit" color="themepurple"><v-icon>fas fa-save</v-icon></v-btn>
							<v-btn class="mr-3 btn" outlined @click="prev()" color="themepurple">Terug</v-btn>
							<v-btn class="btn" @click="nextdialog = true" dark color="themepurple">Volgende</v-btn>
						</v-col>
					</v-row>
				</v-footer>
			</div>

			<!-- Download / submit dialog -->
			<v-dialog v-model="nextdialog" content-class="theme-popup">
				<div class="text-right">
					<v-btn @click="nextdialog = false" icon class="" elevation="0" color="themepurple">
						<v-icon>fas fa-times </v-icon>
					</v-btn>
				</div>
				<div v-if="(sended = true)">
					<div class="text-center pb-5">
						<h3>Weet u zeker dat u het dialoogformulier wil inleveren?</h3>
					</div>
					<div class="text-center">
						<p>Wanneer je hem inlevert word de Procesmanager/Clustermanager/Lijnmanager op de hoogste gesteld.</p>
					</div>
					<div class="text-center pt-5">
						<v-btn class="btn text-primary mr-3" color="#fff">Downloaden</v-btn>
						<v-btn class="btn" color="themepurple" @click="submit('save')" dark>Inleveren</v-btn>
					</div>
				</div>
				<div v-else>
					<div class="text-center pb-5">
						<h3>Verstuurd</h3>
					</div>
					<div class="text-center">
						<p>Document is verstuurd naar organisator.</p>
					</div>
				</div>
			</v-dialog>
		</v-main>
	</div>
</template>

<script>

import api from "../../services/api";
import optiontable from "@/components/optiontable.vue";
import optiontableitem from "@/components/optiontableitem.vue";
import accordion from "@/components/accordion.vue";
import config from '../../config';
import { mapGetters } from 'vuex';
export default {
	components: { optiontable, optiontableitem, accordion },
	data() {
		return {
			sidebar: true,
			ready: true,
			nextdialog: false,
			sended: false,
			edit: false,
			menuitems: [
				{
					title: "Bevindingenformulier Programma",
				},
				{
					title: "1. Het programma",
				},
				{
					title: "2. De ontwikkellijnen ",
				},
			],
			tab: 0,
			data: {
				title: "",
				period: "",
				investment: "",
				successes_and_worries: "",
				are_we_doing_good_work: "",
				are_we_missing_something: "",
				ambition_effect: "",
				attention_subject: [
					"", "", "", "", "",
				],
				perspective_parents: {
					intrest_paramount: "",
					actively_involved: "",
					empowerment_power_responsibility: "",
				},
				perspective_professional: {
					collaboration_improvement: "",
					regulatory_burden: "",
				},
				cost_regulation: {
					effective_aid: "",
					unnecessary_aid: "",
				},
				quality_improvement: {
					closer_to_home: "",
					right_moment: "",
				},
				improvements: {
					are_we_doing_good_things: "",
					are_we_missing_things: "",
					effort_ambition_contribution: ""
				},
			},
		};
	},
	computed: {
		...mapGetters({ user: 'getuser' }),
	},
	methods: {
		submit(type) {
			var data = {
				program: this.user.program,
				data: this.data,
				type: type
			};
			var patchslug = this.$route.params.subid === "nieuw" ? 0 : this.$route.params.subid;
			api.patch(`${config.url.api}/v1/findings/${patchslug}`, data).then((res) => {
				if (res.data.success) {
					//redirect to the newly created recource.
					switch (type) {
						case "concept":
							this.$toast.success('Concept bevindingen formulier succesvol geupdate.');
							break;
						case "save":
							this.$toast.success('Bevindingen formulier succesvol ingeleverd.');
							this.nextdialog = false;
							break;
					}

					if (patchslug == 0) {
						this.$router.push("/bevindingen/" + res.data.slug)
					}

				} else {
					this.$toast.error('Er ging iets mis bij het opslaan van je bevindingen formulier.')
				}
			}).catch((err) => {
				switch (err.response.status) {
					case 401:
						this.$toast.error('Je hebt geen toegang tot dit bevindingen formulier.')
						this.$router.push("/pagina-niet-gevonden")
						break;
					default:
						this.$toast.error(`Er is iets fout gegaan (${err.response.status})`)
						break;
				}
			})
		},
		getFinding() {
			var slug = this.$route.params.subid == undefined ? this.$route.params.id : this.$route.params.subid;
			api.get(`${config.url.api}/v1/findings/${slug}`).then((res) => {
				if (res.data.success) {
					this.data = res.data.data.data;
					this.edit = res.data.userrole == "PM" || res.data.userrole == "OG";
					this.ready = true;
					if (this.$route.params.subid == undefined) {
						this.$router.push("/bevindingen/" + res.data.data.slug)
					}

				} else {
					this.$router.push("/bevindingen/nieuw")
				}
			}).catch((err) => {
				switch (err.response.status) {
					case 401:
						this.$router.push("/pagina-niet-gevonden")
						break;
					case 404:
						this.$router.push("/pagina-niet-gevonden")
						break;
					default:
						this.$toast.error('Fout bij het ophalen van het bevindingen formulier.')
						break;
				}
			})
		},
		next() {
			this.tab++;
			window.scrollTo(0, 0);
		},
		prev() {
			this.tab--;
			window.scrollTo(0, 0);
		},
	},
	mounted() {
		if (this.$route.params.subid != "nieuw") {
			this.getFinding();
		} else {
			this.edit = true;
		}
	},
};
</script>

<style>
body {
	transition: all ease-in-out 0.2s;
	background-color: #d37287 !important;
}
</style>