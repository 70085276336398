<template>
  <v-app v-if="ready">
    <topmenubar />
    <router-view />
  </v-app>
</template>

<script>
import topmenubar from "@/components/topmenubar.vue";
export default {
  components: { topmenubar },
  data() {
    return {
      ready: true,
    };
  },
};
</script>
<style>
body{
  background: #D37287;
}

.footer-buttons{
  width: fit-content !important;
  right: 0 !important;
  left: unset !important;
}

</style>